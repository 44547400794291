@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

:root {
  --unit-100vh: 100vh;
}
@supports (height: 100dvh) {
  :root {
    --unit-100vh: 100dvh;
  }
}

body {
  position: relative;
}

body.dark {
  @apply bg-neutral-900;
}